import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Loader from "./pages/Loader";
import Logout from "./pages/Logout";
import Oops from "./pages/Oops";
import Institutions from "./pages/Institutions";
import Register from "./pages/Register";
import { loadUser } from "./actions/auth";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
  }, []);

  if (state.auth.loginFailed) {
    navigate("/login", { replace: true });
    dispatch({ type: "RESET_REDIRECT_LOGIN" });
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/institutions" element={<Institutions />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
