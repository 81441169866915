import { useState, Fragment, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import { login } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { API_URL } from "../actions/auth";
import { themeStyle, themeStyleDark } from "../components/Components";

export default function Login() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [clearText, setClearText] = useState("");

  useEffect(() => {
    setLoaded(true);
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    dispatch(login(phoneNumber, password));
  }

  if (state.auth.alert) {
    if (state.auth.alert.title == "Welcome!") {
    } else {
      setSnack({
        title: state.auth.alert.title,
        message: state.auth.alert.message,
        type: state.auth.alert.type,
      });
      setShowSnack(true);
      dispatch({ type: "REMOVE_ALERT" });
    }
  }

  console.log(state.auth.theme);

  if (state.auth.isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          open={showSnack}
          onClose={() => setShowSnack(false)}
        >
          <Card style={{ minWidth: "350px", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {snack["type"] == "SUCCESS" ? (
                  <CheckCircleIcon
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontSize: "44px",
                    }}
                  />
                ) : snack["type"] == "MESSAGE" ? (
                  <ErrorIcon
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonColor
                          : themeStyle.primaryButtonColor,
                      fontSize: "44px",
                    }}
                  />
                ) : (
                  <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
                )}
                <div style={{ marginLeft: "10px" }}>
                  <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                  <br></br>
                  <span>{snack["message"]}</span>
                </div>
              </div>
              <IconButton size="small">
                <CloseIcon
                  onClick={() => setShowSnack(false)}
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonColor
                        : themeStyle.primaryButtonColor,
                  }}
                />
              </IconButton>
            </div>
          </Card>
        </Snackbar>
        <AppBar
          position="fixed"
          style={{
            backgroundColor:
              state.auth.theme == "dark"
                ? themeStyleDark.primaryCardColor
                : themeStyle.primaryCardColor,
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "50px", margin: "10px" }}
              src={require("../img/appbar.png")}
            />
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 1s",
            opacity: loaded ? 1 : 0,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage:
                "linear-gradient(to right, rgba(0,0,0,0.6),  rgba(0, 0, 0, 0.6)), url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1172&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
              height: "100%",
              paddingTop: "50px",
              flexDirection: "column",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                boxShadow: "0 8px 18px 0 rgba(0,0,0,0.2)",
                padding: "20px",
                borderRadius: "6px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryCardColor
                    : themeStyle.primaryCardColor,
                paddingRight: "20px",
                maxWidth: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
              >
                <div style={{ marginTop: "-7px" }}>
                  <span
                    style={{
                      fontSize: "32px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                  >
                    Techcards for Schools
                  </span>
                  <br></br>
                  <span
                    style={{
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                  >
                    Dashboard Login
                  </span>
                </div>
                <img
                  style={{ maxHeight: "60px", borderRadius: 15 }}
                  src={require("../img/onlylogo.png")}
                />
              </div>
              <div
                className={state.auth.alert ? "shake" : ""}
                style={{
                  height: "20px",
                  marginTop: "15px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {state.auth.alert
                  ? state.auth.alert.type == "ERROR"
                    ? state.auth.alert.message
                    : ""
                  : ""}
              </div>
              <div
                style={{
                  margin: "5px 0px 0px 0px",
                }}
              >
                <form onSubmit={onSubmit}>
                  <TextField
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="phonenumber"
                    autoComplete="phonenumber"
                    autoFocus
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <TextField
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    margin="normal"
                    placeholder="Password"
                    type={clearText ? "text" : "password"}
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          style={{ marginRight: "-8px", paddingLeft: "10px" }}
                          onClick={() => setClearText(!clearText)}
                        >
                          {clearText ? (
                            <VisibilityOffIcon
                              style={{
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <Link
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                      }}
                      to="/forgotpassword"
                    >
                      Forgot Password?
                    </Link>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      style={{
                        height: "30px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryButtonColor
                            : themeStyle.primaryButtonColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryButtonTextColor
                            : themeStyle.primaryButtonTextColor,
                      }}
                      type="submit"
                      variant="contained"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          width: "100px",
                        }}
                      >
                        Login
                      </span>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Box style={{ position: "absolute", right: 20, bottom: 20 }}>
            <div
              variant="body2"
              color={
                state.auth.theme == "dark"
                  ? themeStyleDark.textColor
                  : themeStyle.textColor
              }
              align="center"
            >
              {"Copyright © "}
              Techcards {new Date().getFullYear()}
              {"."}
            </div>
          </Box>
        </div>
      </Fragment>
    );
  }
}
