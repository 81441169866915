import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import SchoolIcon from "@mui/icons-material/School";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalInstitutions, setTotalInstitutions] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["send_to"] = selectedNoti;
    body["title"] = subject;
    body["message"] = message;

    axios.post(API_URL + "send_notification", body, config).then((res) => {
      createAlert("SUCCESS", "Sent.", "Notification sent!");
    });
  }

  function getData() {
    axios.get(API_URL + "get_dashboard").then((res) => {
      setTotalUsers(res.data["total_users"]);
      setTotalInstitutions(res.data["total_institutions"]);
      setTotalStudents(res.data["total_students"]);
      setIsLoading(false);
    });
  }

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  var data = {
    labels: ["Total", "Paid", "Free"],
    datasets: [
      {
        label: "# of Votes",
        data: [totalUsers, totalInstitutions, totalStudents],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                padding: "150px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "120vh",
                width: "100vw",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "40%",
                          paddingLeft: "10%",
                        }}
                      >
                        <SchoolIcon
                          style={{
                            padding: "10px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "60%",
                          height: "100%",
                          paddingRight: "20%",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Total Institutions
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalInstitutions}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "40%",
                          paddingLeft: "10%",
                        }}
                      >
                        <PersonIcon
                          style={{
                            padding: "10px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "60%",
                          height: "100%",
                          paddingRight: "20%",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Total Teachers
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "40%",
                          paddingLeft: "10%",
                        }}
                      >
                        <CurrencyRupeeIcon
                          style={{
                            padding: "10px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "60%",
                          height: "100%",
                          paddingRight: "20%",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Total Students
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalStudents}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "20px",
                      borderRadius: "15px",
                      marginTop: "7px",
                      height: "100%",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "5px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        marginBottom: "35px",
                      }}
                    >
                      Send Notifications
                    </h2>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item md={4}>
                        {selectedNoti == "EVERYONE" ? (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "PAID USERS" ? (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Subject"
                      variant="outlined"
                      value={subject}
                      size="small"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <br></br> <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Message"
                      variant="outlined"
                      size="small"
                      style={{
                        marginBottom: "25px",
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <br></br> <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          fontSize: "20px",
                          marginTop: "-5px",
                          height: "35px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                        onClick={() => sendNotification()}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            color: "white",
                          }}
                        >
                          Send
                        </span>
                        <ChevronRightIcon
                          style={{
                            fontSize: "30px",
                            color: "white",
                            marginRight: "-10px",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "460px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "40px",
                      borderRadius: "15px",
                      marginTop: "7px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <Doughnut data={data} options={{ cutout: "80%" }} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
