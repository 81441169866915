import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import QRCode from "qrcode";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { json2csv } from "json-2-csv";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Institutions() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState({ profiles: [] });
  const [institutions, setInstitutions] = useState([]);
  const [random, setRandom] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState("");

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const downloadCSVFromJson = () => {
    var tempprofiles = Array.from(teacher["profiles"]);
    for (var j = 0; j < tempprofiles.length; j++) {
      tempprofiles[j]["qr"] =
        "https://id.techcards.in/" +
        tempprofiles[j]["profile_link"] +
        "/" +
        teacher["uid"];
    }

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" +
        encodeURIComponent(json2csv(tempprofiles))
    );
    link.setAttribute("download", teacher["name"]);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function qrcodegen(url, index) {
    QRCode.toDataURL(
      url,
      {
        width: 2000,
        margin: 5,
        color: { dark: "#000000FF", light: "#EEEEEEFF" },
      },
      (err, qc) => {
        if (err) return console.error(err);
        var tempteacher = teacher;
        teacher["profiles"][index]["qr"] = qc;
        setTeacher(tempteacher);
        setRandom(makeid(5));
      }
    );
  }

  function getInstitutions() {
    var states = institutions.map((institution, index) => (
      <MenuItem value={institution["id"]}>{institution["name"]}</MenuItem>
    ));
    return states;
  }

  function getTeachers() {
    var states = teachers.map((teach, index) => (
      <MenuItem value={teach}>{teach["name"]}</MenuItem>
    ));
    return states;
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_institutions", body, config).then((res) => {
      setIsLoading(true);
      setInstitutions(res.data);
      setSelectedInstitution(res.data[0]["id"]);
      body["institution_id"] = res.data[0]["id"];
      axios.post(API_URL + "get_teachers", body, config).then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          for (var j = 0; j < res.data[i]["profiles"].length; j++) {
            res.data[i]["profiles"][j]["qr"] = "";
          }
        }
        setTeachers(res.data);
        setTeacher(res.data[0]);
        setIsLoading(false);
      });
    });
  }

  function handleInstitution(institution) {
    setSelectedInstitution(institution);
    var body = {
      email: state.auth.user.email,
    };
    body["institution_id"] = institution;

    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    axios.post(API_URL + "get_teachers", body, config).then((res) => {
      setIsLoading(true);
      setTeachers(res.data);
      setTeacher(res.data[0]);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  // function setHasBought(index, checked) {
  //   var tempprofiles = profiles;
  //   tempprofiles[index]["has_bought"] = checked;
  //   setProfiles(tempprofiles);
  //   setRandom(makeid(5));

  //   var body = {
  //     uid: tempprofiles[index]["uid"],
  //     bought: checked,
  //   };
  //   const config = {
  //     headers: {
  //       Authorization: state.auth.token,
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   axios.post(API_URL + "update_bought", body, config).then((res) => {});
  // }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                padding: "100px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHeight: "120vh",
                width: "100vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                    fontSize: "20px",
                    marginBottom: "30px",
                    borderRadius: "60px",
                    padding: "20px 30px 20px 30px",
                  }}
                  onClick={() => {
                    downloadCSVFromJson();
                  }}
                >
                  DOWNLOAD CSV
                </Button>
              </div>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              >
                <Grid item md={6}>
                  <p>Select Institution</p>
                  <Select
                    value={selectedInstitution}
                    onChange={(e) => {
                      handleInstitution(e.target.value);
                    }}
                    style={{
                      marginTop: "-10px",
                      height: "40px",
                      backgroundColor: "white",
                    }}
                    fullWidth
                    variant={"outlined"}
                  >
                    {getInstitutions()}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <p>Select Teacher</p>
                  <Select
                    value={teacher}
                    onChange={(e) => {
                      setTeacher(e.target.value);
                    }}
                    style={{
                      marginTop: "-10px",
                      height: "40px",
                      backgroundColor: "white",
                    }}
                    fullWidth
                    variant={"outlined"}
                  >
                    {getTeachers()}
                  </Select>
                </Grid>
              </Grid>
              {teachers.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1>No teachers found</h1>
                </div>
              ) : (
                <>
                  {" "}
                  <div
                    style={{
                      marginTop: "20px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      borderRadius: "15px",
                      padding: "15px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{
                        justifyContent: "flex-start",
                        textAlign: "left",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        paddingBottom: "20px",
                        borderBottom:
                          state.auth.theme == "dark"
                            ? "1px solid" + themeStyleDark.primaryColor
                            : "1px solid" + themeStyle.primaryColor,
                      }}
                    >
                      <Grid item md={1}>
                        No.
                      </Grid>
                      <Grid item md={4}>
                        Full Name
                      </Grid>
                      <Grid item md={3}>
                        Email
                      </Grid>
                      <Grid item md={3}>
                        Phone
                      </Grid>
                      <Grid item md={1}>
                        QR
                      </Grid>
                    </Grid>
                    {teacher["profiles"].map((user, index) => (
                      <Grid
                        onClick={() => {
                          console.log(user);
                        }}
                        container
                        spacing={2}
                        style={{
                          color: "black",
                          padding: "15px",
                        }}
                      >
                        <Grid item md={1}>
                          {index + 1}
                        </Grid>
                        <Grid item md={4}>
                          {user["name"]}
                        </Grid>
                        <Grid item md={3}>
                          {user["email"]}
                        </Grid>
                        <Grid item md={3}>
                          {user["phone_number"]}
                        </Grid>
                        <Grid
                          item
                          md={1}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {user["qr"].length == 0 ? (
                            <Button
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                qrcodegen(
                                  "https://id.techcards.in/" +
                                    user["profile_link"] +
                                    "/" +
                                    teacher["uid"],
                                  index
                                );
                              }}
                            >
                              <QrCodeIcon
                                style={{
                                  fontSize: "30px",
                                  marginTop: "-5px",
                                  color: themeStyle.primaryColor,
                                  textAlign: "center",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button
                              style={{ cursor: "pointer" }}
                              href={user["qr"]}
                              download={user["profile_link"] + ".png"}
                            >
                              <DownloadIcon
                                style={{
                                  marginTop: "-5px",
                                  fontSize: "30px",
                                  color: themeStyle.primaryColor,
                                  textAlign: "center",
                                }}
                              />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </>
              )}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Institutions;
